<template>
    <div id="search-page">
        <div class="">

          <div class="flex items-center">
            <b-form-input @keyup.enter.native="searchUser()"           @click="stopMenu()" placeholder="Digite nome de usuário" v-model="searchQuery" class="input-rounded-full" />
          </div>
          <div class="search-meta flex flex-wrap justify-between mt-6">
              <span class="mb-4">{{searchResults.length.toString()}} Resultados</span>
          </div>
        </div>
        <!-- SEARCH RESULTS -->
        <div class="vx-row mt-4 md:flex-row flex-col-reverse">
            <div class="vx-col md:w-full lg:w-full w-full">
                <b-card class="search-page__search-results lg:p-2">
                    <OneLineDataResultMasterUsers  @callReload="reload" :lines="searchResults"></OneLineDataResultMasterUsers>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
import OneLineDataResultMasterUsers from '@/components/one-line-data-result/OneLineDataResultMasterUsers.vue'
import {BCard, BDropdownItem, BFormInput} from 'bootstrap-vue'
export default{
  data () {
    return {
      searchQuery: '',
      currentPage: 1,
      searchResults: [],
    }
  },
  computed: {},
  methods: {
    searchUser() {
        this.$router.replace({ query: { u: this.searchQuery } })
        this.$httpPlatform.post('https://api.bingolar.tv/api/platform-operations/userSearchMaster',{
          name: this.searchQuery,
          invite_code: this.inviteCode,
        })
        .then(res => {
            this.searchResults = res.data
            console.log(res.data)
        })
    },
    reload(){
      if(this.$route.query.u){
        this.searchQuery = this.$route.query.u;
      }
      this.searchUser();

    },
    stopMenu(){
      console.log("hey")
      this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)

    }
  },
  components: {
    OneLineDataResultMasterUsers,
    BCard,BFormInput,BDropdownItem
  },
  created() {
    this.inviteCode = JSON.parse(localStorage.getItem('userData')).inviteCode;
    this.reload();
  }
}
</script>


